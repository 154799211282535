@font-face{
    font-family: Ivy Mode Regular;
    src: url(../assets/fonts/IvyMode-Regular.otf);
}

@font-face{
    font-family: Ivy Mode Italic;
    src: url(../assets/fonts/IvyMode-Italic.otf);
}

@font-face{
    font-family: Ivy Mode Bold;
    src: url(../assets/fonts/IvyMode-Bold.otf);
}

@font-face{
    font-family: Ivy Mode Bold Italic;
    src: url(../assets/fonts/IvyMode-BoldItalic.otf);
}

@font-face{
    font-family: Gill Sans;
    src: url(../assets/fonts/GillSans.otf);
}


@font-face{
    font-family: Gill Sans Medium;
    src: url(../assets/fonts/GillSans-Medium.otf);
}


@font-face{
    font-family: Gill Sans Bold;
    src: url(../assets/fonts/GillSans-Bold.otf);
}


@font-face{
    font-family: Gill Sans Light;
    src: url(../assets/fonts/GillSans-Light.otf);
}

@font-face{
    font-family: Gill Sans Light Italic;
    src: url(../assets/fonts/GillSans-LightItalic.otf);
}

@mixin verticalCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin fullScreen{
    width: 100vw;
    height: 100vh;
}

$font-ivymode-regular: Ivy Mode Regular;
$font-ivymode-italic: Ivy Mode Italic;
$font-ivymode-bold: Ivy Mode Bold;
$font-ivymode-bold-italic: Ivy Mode Bold Italic;
$font-gillsans: Gill Sans;
$font-gillsans-medium: Gill Sans Medium;
$font-gillsans-bold: Gill Sans Bold;
$font-gillsans-light: Gill Sans Light;
$font-gillsans-light-italic: Gill Sans Light Italic;

$bg-color: #101214;
$color-white: #FFFFFF;
$color-gray: #616263;

.ivymode-regular{
    font-family: $font-ivymode-regular;
}

.ivymode-italic{
    font-family: $font-ivymode-italic;
}

.ivymode-bold{
    font-family: $font-ivymode-bold;
}

.ivymode-bold-italic{
    font-family: $font-ivymode-bold-italic;
}

.gillsans-light{
    font-family: $font-gillsans-light;
}

.gillsans-light-italic{
    font-family: $font-gillsans-light-italic;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    @include fullScreen;
    background-color: $bg-color;
}

body, html{
    position: relative;
}

.fade-in{
    transition: opacity 2s ease;
}

.fade-out{
    opacity: 0;
    transition: opacity 1s ease;
}

.circle {
    position: absolute;
    border: solid 1px #ccc;
    width: 5px; 
    height: 5px; 
    border-radius: 50%;  
    background-color: $color-white;
}







