.main-splash-container{
    @include fullScreen;
    position: absolute;
    padding: 60px 60px 0 60px;

    .main-splash-header{
        display: flex;
        width: 100%;

        .main-splash-header-left{
            width: 50%;
            span{
                color: $color-white;
                font-family: $font-gillsans;
                font-size: 16.5px;

                &:first-child{
                    padding-right: 10px;
                    border-right: 1px solid gray;
                }

                &:last-child{
                    padding-left: 10px;
                }
            }
        }

        .main-splash-header-right{
            width: 50%;        
            text-align: end;

            a{
                position: relative;
                text-decoration: none;
                color: $color-white;
                font-family: $font-gillsans-light;
                font-size: 16.5px;


                &:after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: -10px;
                    left: 0;
                    background-color: $color-white;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transition: 0.25s ease-out;
                    transition: transform 0.25s ease-out;
                    transform-origin: center;

                }

                &:hover:after{
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                    transform-origin: center;
                }
            }

        }
    }

    .main-splash-content{
        display: flex;
        position: relative; 
        height: 84%;

        .main-splash-content-left{
            position: relative;
            width:50%;

            ul{
                list-style: none;
                margin-top: 20vh;
                a{
                    display: block;
                    width: 25px;
                    font-size: 16.5px;
                    color: $color-white;
                    text-decoration: none;
                    font-family: $font-gillsans-light;
                    li{
                        position: relative;
                        margin-top: 30px;
                        writing-mode: tb-rl;
                        transform: rotate(-180deg);
                        padding-left: 10px;
                        border-left: 1px solid transparent;

                        &:after{
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 1px;
                            top: 0;
                            left: 0;
                            background-color: $color-white;
                            visibility: hidden;
                            -webkit-transform: scaleY(0);
                            transform: scaleY(0);
                            -webkit-transition: 0.25s  ease-out;
                            transition: 0.25s  ease-out;
                            transform-origin: center;
                        }
        
                        &:hover:after{
                            visibility: visible;
                            -webkit-transform: scaleY(1);
                            transform: scaleY(1);
                        }
                    }
                }
            }

            p{
                position: absolute;
                font-size: 16.5px;;
                color: $color-white;
                font-family: $font-gillsans-light-italic;
                bottom: -20px;
            }
        }

        .main-splash-content-right{
            position: absolute;
            text-align: right;
            bottom: 0;
            right: 0;

            p{
                color: $color-white;
                font-size: 41px;
                font-family: $font-ivymode-regular;
            }
        }
    }


}

@media screen and (max-width: 1180px) {
    .main-splash-mobile-container{
        @include fullScreen;
        position: absolute;
        padding: 20px 20px 0 20px;
    
        .main-splash-mobile-header{
            display: flex;

            svg{
                width: 130px;
                height: 130px
            }

        }
    
        .main-splash-mobile-content{
            padding-top: 8vh;
            text-align: center;
    
            .text-container{
                color: $color-white;
                font-size: 19px;
                font-family: $font-ivymode-regular;
            }
    
            .mailto-container{
                padding-top: 10vh;
    
                a{
                    position: relative;
                    text-decoration: none;
                    color: $color-white;
                    font-family: $font-gillsans-light;
                    font-size: 15px;

                    &:after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: -10px;
                        left: 0;
                        background-color: $color-white;
                        animation: ease-out 2s alternate infinite underline-from-center;
                    }
                }
    
            }
        }
    
        .main-splash-mobile-footer{
    
            padding-top: 20vh;
            text-align: center;
            width: 100%;
    
            ul{
                list-style: none;
                
                a{
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 40px;

                    li{
                        color: $color-white;
                        font-family: $font-gillsans-light;
                        font-size: 14px;
                    }

                    &:last-child{
                        margin-right: 0px;
                    }
 
                }
    
    
            }
            
        }
    
    }
}


@keyframes underline-from-center {
    from { transform: scaleX(0); }
    to   { transform: scaleX(1); }
  }


