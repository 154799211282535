.logo-splash-container{
    @include verticalCenter;
    @include fullScreen;
    position: absolute;
    display: flex;
    align-items: center;
    
    .logo-container{
        svg{
            width: 250px;
            height: 250px;
        }
    }

    .coming-soon-container{
        display: none;
    }
    


}

@media screen and (max-width: 1180px) {

    .logo-splash-container{

        .logo-container{
            svg{
                margin-bottom: 12vh;
            }
        }

        .coming-soon-container{
            display: block;
            position: fixed;
            bottom: 0;
            padding-bottom: 60px;
            p{
                color: $color-white;
                font-family: $font-gillsans-light;
                font-size: 14px;
            }
        }
        

    
    }
}